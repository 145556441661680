import React, { useRef, useEffect } from 'react';

const PixelatedShaderImageLoop = ({ name = 'tanel', width = 320, height = 400 }) => {
  const canvasRef = useRef();
  const mouse = useRef({ x: 0.5, y: 0.5 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const gl = canvas.getContext('webgl');
    if (!gl) return;

    const frameDuration = Math.random() * 10 + 1000;
    const seed = Math.random() * 1000;

    const vertexSrc = `
      attribute vec4 a_position;
      attribute vec2 a_texCoord;
      varying vec2 v_texCoord;
      void main() {
        gl_Position = a_position;
        v_texCoord = a_texCoord;
      }`;

    const fragmentSrc = `
      precision mediump float;
      varying vec2 v_texCoord;
      uniform sampler2D u_texture;
      uniform float u_time;
      uniform vec2 u_mouse;
      uniform float u_mouseActive;
      uniform float u_seed;

      float rand(vec2 co) {
        return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
      }

      vec3 pickHighlightColor(float seed) {
        if (seed < 0.15) return vec3(0.76, 1.0, 0.71);
        if (seed < 0.3) return vec3(1.0, 0.64, 0.71);
        if (seed < 0.45) return vec3(0.87, 0.66, 1.0);
        return vec3(0.63, 0.82, 1.0);
      }

      void main() {
        float pixelSize = 0.0125;
        vec2 gridUV = floor(v_texCoord / pixelSize) * pixelSize;

        vec2 uv = gridUV;
        vec4 color = texture2D(u_texture, uv);
        float brightness = dot(color.rgb, vec3(0.299, 0.587, 0.114));

        if (brightness > 0.05) {
          float dist = distance(v_texCoord, u_mouse);
          float cursorInfluence = smoothstep(0.25, 0.0, dist) * u_mouseActive;

          float personalTime = u_time * 0.7 + rand(uv * 100.0 + u_seed) * 100.0;
          float flickerFade = smoothstep(0.0, 1.0, 0.5 + 0.5 * sin(personalTime));
          float flicker = mix(0.2, 1.0, flickerFade); // varied alpha

          float highlightChance = mix(0.05, 0.2, cursorInfluence); // more color on hover
          float highlightSeed = rand(uv * 200.0 + u_seed);
          float colorFade = smoothstep(0.0, 1.0, 0.5 + 0.5 * sin(u_time * 0.3 + highlightSeed * 6.2831));

          if (rand(uv * 20.0 + u_seed) < highlightChance) {
            color.rgb = pickHighlightColor(highlightSeed);
            flicker = 1.0;
          }

          color.rgb += cursorInfluence * 0.1;
          color.rgb = (color.rgb - 0.5) * 1.15 + 0.5 + 0.02;
          color.rgb *= flicker;
        }

        color.rgb = mix(vec3(dot(color.rgb, vec3(0.299, 0.587, 0.114))), color.rgb, 1.1);
        gl_FragColor = color;
      }
    `;

    const createShader = (type, source) => {
      const shader = gl.createShader(type);
      gl.shaderSource(shader, source);
      gl.compileShader(shader);
      return shader;
    };

    const vertexShader = createShader(gl.VERTEX_SHADER, vertexSrc);
    const fragmentShader = createShader(gl.FRAGMENT_SHADER, fragmentSrc);
    const program = gl.createProgram();
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    gl.useProgram(program);

    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
      -1, -1, 0, 1,
       1, -1, 1, 1,
      -1,  1, 0, 0,
       1,  1, 1, 0,
    ]), gl.STATIC_DRAW);

    const aPosition = gl.getAttribLocation(program, 'a_position');
    const aTexCoord = gl.getAttribLocation(program, 'a_texCoord');
    gl.enableVertexAttribArray(aPosition);
    gl.vertexAttribPointer(aPosition, 2, gl.FLOAT, false, 16, 0);
    gl.enableVertexAttribArray(aTexCoord);
    gl.vertexAttribPointer(aTexCoord, 2, gl.FLOAT, false, 16, 8);

    const uTime = gl.getUniformLocation(program, 'u_time');
    const uMouse = gl.getUniformLocation(program, 'u_mouse');
    const uMouseActive = gl.getUniformLocation(program, 'u_mouseActive');
    const uSeed = gl.getUniformLocation(program, 'u_seed');

    const textures = [];
    let imagesLoaded = 0;

    for (let i = 1; i <= 8; i++) {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = `/${name}360000${i}.png`;
      image.onload = () => {
        const tex = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, tex);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        textures[i - 1] = tex;
        imagesLoaded++;
      };
    }

    let lastMove = Date.now();
    let mouseActive = 0;
    const startTime = Date.now();

    const render = () => {
      const elapsed = Date.now() - startTime;
      gl.viewport(0, 0, canvas.width, canvas.height);
      gl.clear(gl.COLOR_BUFFER_BIT);
      gl.uniform1f(uTime, elapsed / 1000);
      gl.uniform1f(uSeed, seed);
      gl.uniform2f(uMouse, mouse.current.x, mouse.current.y);

      const sinceMove = Date.now() - lastMove;
      mouseActive += sinceMove < 1000 ? 0.05 : -0.02;
      mouseActive = Math.max(0, Math.min(1, mouseActive));
      gl.uniform1f(uMouseActive, mouseActive);

      if (imagesLoaded >= 8) {
        const frameIndex = Math.floor(elapsed / frameDuration) % 8;
        gl.bindTexture(gl.TEXTURE_2D, textures[frameIndex]);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
      }

      requestAnimationFrame(render);
    };

    render();

    const updateMouse = (e) => {
      const rect = canvas.getBoundingClientRect();
      lastMove = Date.now();
      mouse.current.x = (e.clientX - rect.left) / rect.width;
      mouse.current.y = (e.clientY - rect.top) / rect.height;
    };

    canvas.addEventListener('mousemove', updateMouse);
    return () => canvas.removeEventListener('mousemove', updateMouse);
  }, [name, width, height]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{
        width: '100%',
        maxWidth: `${width}px`,
        height: 'auto',
        display: 'block',
      }}
    />
  );
};

export default PixelatedShaderImageLoop;
